import type { GhostPostsByAuthorParams, GhostPostsForBlogParams } from '~/types'

/**
 * A collection of methods to call the Ghost API endpoints with.
 *
 * TODO: there's an opportunity to make this a bit more flexible in the repo, by
 * creating a more generic endpoint for things like `/posts/` and just passing
 * in params that we need. That way we don't need to define 1-on-1 methods, but
 * can just use the generic one(s) when needed.
 *
 * For example: `getPostsByAuthor` could just leverage `getPosts`, but with
 * specific params.
 */
export default function useGhostApi() {
  const { $ghostFetch } = useNuxtApp()
  const ghostRepo = ghostRepository($ghostFetch)
  const { te } = useI18n()

  /**
   * Fetch a single page from the Ghost CMS.
   */
  function getPage(slug: string) {
    return useAsyncData(`ghost-page-${slug}`, () => ghostRepo.getPage(slug), {
      transform: transformGhostPage,
    })
  }

  /**
   * Fetch a single postfrom the Ghost CMS.
   */
  function getPosts(slug: string) {
    return useAsyncData(`ghost-post-${slug}`, () => ghostRepo.getPosts(slug), {
      transform: transformGhostPost,
    })
  }

  /**
   * Fetches all posts by a specific author from the Ghost CMS.
   */
  function getPostsByAuthor(params: ComputedRef<GhostPostsByAuthorParams>) {
    return useAsyncData('ghost-posts-by-author', () => ghostRepo.getPostsByAuthor(params), {
      transform: transformGhostPostsByAuthor,
      watch: [params],
    })
  }

  /**
   * Fetches all posts by a specific tag from the Ghost CMS.
   */
  function getPostsByTag(tag: string) {
    return useAsyncData(`ghost-posts-by-tag-${tag}`, () => ghostRepo.getPostsByTag(tag))
  }

  /**
   * Fetches all blog posts from the Ghost CMS.
   */
  function getPostsForBlog(params: ComputedRef<GhostPostsForBlogParams>) {
    return useAsyncData('ghost-posts', () => ghostRepo.getPostsForBlog(params), {
      watch: [params],
    })
  }

  /**
   * Fetches all tags from the Ghost CMS.
   */
  function getTags() {
    return useAsyncData('ghost-tags', () => ghostRepo.getTags(), {
      transform: (data) => transformGhostTags(data, te),
    })
  }

  /**
   * Handles Ghost API errors.
   */
  function handleGhostError(statusCode: number, type: string, slug: string) {
    throw createError({
      statusCode: statusCode,
      statusMessage: `There was an issue fetching a ${type}: (${slug})`,
    })
  }

  return {
    getPage,
    getPosts,
    getPostsByAuthor,
    getPostsByTag,
    getPostsForBlog,
    getTags,
    handleGhostError,
  }
}
